.content-tile__container {
    padding: 0;
}

.content-tile__video {
    @media only screen and (max-width: 600px) {
        height: 260px !important;
    }
    margin: 50px 0 50px 0;
    width: 100% !important;
}

.video-tile__title {
    margin: 50px 0;
    font-family: 'Courier New', Courier, monospace;
    font-size: large;
    font-weight: 500;
    padding-bottom: 5px;
}

.video-tile__image {
    // padding: 50px 0 50px 0;
    width: 100% !important;
}

.img-overlay-wrap {
    position: relative;
    display: inline-block; /* <= shrinks container to image size */
    transition: transform 150ms ease-in-out;
}
  
.img-overlay-wrap img { /* <= optional, for responsiveness */
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 0 50px 0;
}

.img-overlay-wrap svg {
    position: absolute;
    top: 50%;
    left: 50%;
    display: none;
}
  
.img-overlay-wrap:hover .img-overlay-wrap svg{
    display: contents;
    // transform: rotate( 15deg );
}