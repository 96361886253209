.navbar__image {
    width: 90px;
    height: 80px;
}

.navbar__tab-container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      width: 100%;
    }
}

.navbar__tab-item {
    float: right;
    @media only screen and (max-width: 600px) {
        width: 100%;
        display: inline-grid;
    }
}