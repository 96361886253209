@import './components/Navbar/Navbar.scss';
@import './components/Advisory/Advisory.scss';
@import './components/ContentTile/ContentTile.scss';
@import './components/Footer/Footer.scss';

.content {
    padding: 10px 0 0 0;
    max-width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    @media only screen and (max-width: 600px) {
        padding: 10px;
        max-width: 90%;
        margin-left: 5%;
        margin-right: 5%;
    }
}

// @media (prefers-reduced-motion: no-preference) {
//   .App-logo {
//     animation: App-logo-spin infinite 20s linear;
//   }
// }

// .App-header {
//   background-color: #282c34;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   font-size: calc(10px + 2vmin);
//   color: white;
// }

// .App-link {
//   color: #61dafb;
// }

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }