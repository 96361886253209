.advisory__container {
    margin: 10px;
    padding-bottom: 40px;
}

.advisory__body {
    text-overflow: ellipsis;
    font-family: "Courier New", Courier, monospace;
    font-size: large;
    font-weight: 550;
}